import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios, { AxiosError } from "axios";
import ReportIcon from "@mui/icons-material/Report";

const getErrorMessage = (error: AxiosError) => {
  if (error.response?.data) {
    const data: { message: string } = error.response.data as any;
    return data.message || "Error message";
  }
  if (error.request) {
    return error.request.message || "Error message";
  }
  return error.message;
};

const Error = ({ error }: { error: unknown }) => {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      color="black.main"
    >
      <Typography variant="h1" sx={{ color: "lightBorealBlue.main" }}>
        Oops!
      </Typography>
      <Typography variant="h2" color="lightBorealBlue">
        Something bad happened!
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="40px"
        bgcolor="background.dark"
        mt="30px"
        mb="20px"
        borderRadius="10px"
        p="15px"
      >
        <Box>
          {axios.isAxiosError(error) ? (
            <Box>
              <Typography
                textAlign="center"
                variant="h1"
                sx={{ color: "lightBorealBlue.main", mb: "40px" }}
              >
                {error.status || error.response?.status || 500}
              </Typography>
              <Typography variant="h4">
                <Typography
                  fontWeight={500}
                  variant="h4"
                  sx={{ color: "lightBorealBlue.main" }}
                >
                  Message:{" "}
                </Typography>
                {getErrorMessage(error)}
              </Typography>
              <Typography variant="body16" fontWeight="bold">
                {error.code}
              </Typography>

              {(error.status == "404" || error.response?.status == 404) && (
                <Typography variant="body16" paragraph>
                  <b>Info: </b>
                  Your user is not registered! Please contact administrator!
                </Typography>
              )}
            </Box>
          ) : (
            <Typography variant="h3">
              <b>Message: </b> Unexpected error happened!
            </Typography>
          )}
        </Box>
        <Box>
          <ReportIcon color="error" sx={{ fontSize: 200, opacity: 0.5 }} />
        </Box>
      </Box>
      <Button
        size="large"
        variant="contained"
        onClick={() => window.location.reload()}
      >
        Try again
      </Button>
    </Box>
  );
};

export default Error;
