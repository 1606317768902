import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box } from "@mui/material";

import ScrollToTop from "utils/ScrollToTop";
import Footer from "../layouts/Footer/Footer";
import Header from "../layouts/Header/Header";
import RoutesList from "Routes";
import Login from "pages/Unauthenticated/Login/Login";

export const App = () => {
  return (
    <Box
      minHeight="100vh"
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ backgroundColor: "background.main" }}
    >
      <AuthenticatedTemplate>
        <Box
          display="flex"
          width="100%"
          minHeight="100vh"
          alignItems="center"
          flexDirection="column"
          gap="25px"
        >
          <ScrollToTop>
            <RoutesList />
          </ScrollToTop>
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </Box>
  );
};

export default App;
