import { SxProps } from "@mui/material";
import { Box } from "@mui/system";

type DividerProps = {
  opacity?: number | string;
  height?: string;
  sx?: SxProps;
  horizontal?: boolean;
};

const Divider = ({ opacity = 1, height = "100%", horizontal, sx }: DividerProps) => {
  return (
    <Box
      height={height}
      borderLeft={horizontal ? 0 : "1px solid"}
      borderTop={horizontal ? "1px solid" : 0}
      ml={horizontal ? 0 : "1px"}
      mt={horizontal ? "1px" : 0}
      sx={{ opacity, ...sx }}
    ></Box>
  );
};

export default Divider;
