import axios from "axios";
const { REACT_APP_MTM_API_URL, NODE_ENV } = process.env;

const createApiInstance = (options = {}) => {
  return axios.create({
    baseURL:
      NODE_ENV === "development"
        ? "http://127.0.0.1:3000"
        : REACT_APP_MTM_API_URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });
};

const mtmApiInstance = createApiInstance();

export default mtmApiInstance;
