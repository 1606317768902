import create from "zustand";

type UserStoreState = {
  token?: string;
  role?: string;
  roleId?: number;
  brandId?: number;
};

export const useUserStore = create<UserStoreState>((_set) => ({}));
