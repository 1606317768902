import { Box, Drawer, Typography } from "@mui/material";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import {
  MoveToInboxOutlined,
  InsightsOutlined,
  SettingsOutlined,
  FolderCopyOutlined,
  HomeOutlined,
} from "@mui/icons-material";
import AuraBcLogo from "assets/logos/aura-white.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { useUserStore } from "store/userStore";
import { useThemeStore } from "store/themeStore";

const NavLink = ({
  children,
  to,
  icon,
  modifierClass,
  ...props
}: LinkProps & { icon: JSX.Element; modifierClass?: string }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className={
        modifierClass ? `${modifierClass} side-nav-link` : "side-nav-link"
      }
      style={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        marginBottom: "30px",
      }}
      to={to}
      {...props}
    >
      {icon}
      <Typography
        variant="body16"
        sx={{
          cursor: "pointer",
          color: "white.main",
          textDecoration: "none",
          "&:hover": { fontWeight: "bold" },
        }}
      >
        {children}
      </Typography>
      {match && (
        <Box
          left="0"
          position="absolute"
          width="3px"
          height="24px"
          borderRadius="10px"
          bgcolor="lightBorealBlue.100"
        />
      )}
    </Link>
  );
};

const SideNav = () => {
  const hideSideNav = useThemeStore((state) => state.hideSideNav);
  const navigate = useNavigate();

  const handleLogout = () => {
    useUserStore.setState({ token: undefined });
    navigate("/login");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const NAV_ITEMS: {
    to: string;
    icon: JSX.Element;
    text: string;
    className?: string;
  }[] = [
    {
      to: "/",
      icon: <HomeOutlined color="info" />,
      text: "Home",
    },
    {
      to: "/collections",
      icon: <FolderCopyOutlined color="info" />,
      text: "My Collections",
    },
    // {
    //   to: "/smart-contract-library",
    //   icon: <MoveToInboxOutlined color="info" />,
    //   text: "Smart Contract Library",
    // },
    // {
    //   to: "/analytics",
    //   icon: <InsightsOutlined color="info" />,
    //   text: "Analytics",
    // },
    {
      to: "/settings",
      icon: <SettingsOutlined color="info" />,
      text: "Settings",
    },
  ];
  const drawerWidth = { lg: 200, xl: 240 };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "background.black",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "background.black",
        },
        display: { xs: "none", lg: hideSideNav ? "none" : "flex" },
      }}
      variant="permanent"
      anchor="left"
    >
      <Link to="/">
        <Box
          sx={{
            height: "auto",
            maxHeight: { xs: "37px", lg: "60px" },
            width: "auto",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "156px",
            mt: "25px",
            ml: { lg: "20px", xl: "35px" },
          }}
          component="img"
          alt="Aura World"
          src={AuraBcLogo}
        />
      </Link>{" "}
      <Box p="50px 40px" mt="30px">
        {NAV_ITEMS.map(({ to, icon, text, className }) => (
          <NavLink key={text} to={to} icon={icon} modifierClass={className}>
            {text}
          </NavLink>
        ))}
      </Box>
      <Box bgcolor="background.black" height="2px" width="100%" />
      <Box
        bgcolor="background.dark"
        height="54px"
        display="flex"
        justifyContent="center"
        width="100%"
        data-testid="logged-in-screen-disconnect"
        sx={{
          marginTop: { xs: "30px", ml: "auto" },
          marginBottom: { xs: "100px", ml: "0" },
          cursor: "pointer",
          "&:hover": {
            bgcolor: "background.light",
          },
        }}
        onClick={handleLogout}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="8px"
        >
          <LogoutIcon />
          <Typography variant="body16" color="greyBlue.light">
            Logout
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideNav;
