import { useMsal } from "@azure/msal-react";
import { Button, Typography } from "@mui/material";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "config/authConfig";
import { Box } from "@mui/system";
import Header from "layouts/Header/Header";
import Footer from "layouts/Footer/Footer";

const AURA_LOGO =
  "https://stluxnftdevglb.blob.core.windows.net/brands/aura/brandLogo-1664176175907.svg";

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = async (instance: PublicClientApplication) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Box
      sx={{ backgroundColor: "background.light" }}
      display="flex"
      width="100%"
      minHeight="100vh"
      alignItems="center"
      flexDirection="column"
      gap="25px"
    >
      <Header />
      <Box
        display="flex"
        bgcolor="background.dark"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        zIndex="1"
        borderRadius="8px"
        sx={{
          width: {
            xs: "100%",
            ml: "90%",
            md: "450px",
          },
          height: {
            xs: "400px",
            lg: "525px",
          },
          mt: {
            md: "-40px",
            lg: "-70px",
          },
          mb: {
            ml: "50px",
            md: "90px",
            lg: "70px",
          },
        }}
      >
        <Box
          component="img"
          alt="aura-admin-nft"
          src={AURA_LOGO}
          height="80px"
        />
        <Typography variant="h3" color="white.main" textAlign="center">
          Aura MTM Admin BackOffice
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => handleLogin(instance as PublicClientApplication)}
        >
          Sign in
        </Button>
      </Box>{" "}
      <Footer />
    </Box>
  );
};
export default Login;
