import Drawer from "@mui/material/Drawer";
import { FC } from "react";
import { Box } from "@mui/system";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import Divider from "components/UI/Divider";
import { Logout } from "@mui/icons-material";
import { useUserStore } from "store/userStore";

const MenuItem = ({ children, to, onClick, ...props }: LinkProps) => {
  return (
    <Link
      onClick={onClick}
      style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
      to={to}
      {...props}
    >
      <Typography
        variant="h4"
        textAlign="center"
        sx={{
          cursor: "pointer",
          color: "background.dark",
          textDecoration: "none",
          "&:hover": { color: "primary.main" },
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};

export const MenuDrawer: FC<{ open: boolean; handleClose: () => void }> = ({
  open,
  handleClose,
}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    useUserStore.setState({ token: undefined });
    navigate("/login");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: {
            xs: "95%",
            sm: "320px",
          },
          py: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
      anchor="right"
      open={open}
      disableScrollLock={true}
      onClose={handleClose}
    >
      <Box position="absolute" top="15px" right="15px">
        <IconButton onClick={handleClose} size="small">
          <Close fill="#666666" />
        </IconButton>
      </Box>
      <Box
        maxWidth="140px"
        width="140px"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          gap: "20px",
        }}
        data-testid="menu-drawer-nav"
      >
        <MenuItem onClick={handleClose} to="/collections">
          My collections
        </MenuItem>
        {/* <Divider
          horizontal
          sx={{ borderColor: "greyBlue.light", width: "100%" }}
        />
        <MenuItem onClick={handleClose} to="/smart-contract-library">
          Smart contract library
        </MenuItem> */}
        <Divider
          horizontal
          sx={{ borderColor: "greyBlue.light", width: "100%" }}
        />
        <MenuItem onClick={handleClose} to="/settings">
          Settings
        </MenuItem>

        <Divider
          horizontal
          sx={{ borderColor: "greyBlue.light", width: "100%" }}
        />
        {/* <MenuItem onClick={handleClose} to="/analytics">
          Analytics
        </MenuItem> */}
      </Box>
      <Box
        height="54px"
        display="flex"
        justifyContent="center"
        width="100%"
        data-testid="logged-in-screen-disconnect-menu-drawer"
        sx={{
          marginTop: { xs: "30px", ml: "auto" },
          marginBottom: { xs: "100px", ml: "0" },
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="8px"
        >
          <Logout />
          <Typography variant="body16" color="background.dark">
            Logout
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
