import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)<any>(
  ({ contentgap }: { contentgap?: string }) => ({
    padding: '30px',
    '& .MuiDialogContent-root': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: contentgap || '30px',
    },
  })
);
