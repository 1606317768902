import { useEffect, useRef, useState, useMemo } from "react";
import { Filter, Role, User } from "utils/types";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import Loader from "components/UI/Loader";
import { useNavigate } from "react-router-dom";
import { StyledTextField } from "components/UI/StyledTextFields";
import FilledSelect from "components/UI/FilledSelect";
import { ReactComponent as SearchIcon } from "assets/icons/search-icon.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { calculateNumberOfPages, updateUniqueSCArray } from "utils/helpers";
import { fetchRoles, fetchUsers } from "services/userService";
import { StyledTableContainer } from "components/UI/StyledTable";
import { ReactComponent as RedirectIcon } from "assets/icons/arrow-enter.svg";
import Tag from "components/UI/Tag";
import { useUserStore } from "store/userStore";

const LIMIT = 1000;
const PAGE_SIZE = 10;

function paginate(array: User[], page_size: number, page_number: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const UserStyledTableCell = styled(TableCell)<any>(
  ({ theme }: { theme: any }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.grey,
      borderBottom: `1px solid ${theme.palette.black[20]}`,
      padding: "20px",
      ...theme.typography.specialTag,
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "transparent",
      borderBottom: `1px solid ${theme.palette.black[20]}`,
      padding: "16px",
      ...theme.typography.body14,
    },
    color: theme.palette.black.main,
  })
);

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [roleFilter, setRoleFilter] = useState<Filter[]>([]);
  const [selectedRole, setSelectedRole] = useState<number>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const loadRef = useRef<HTMLElement>(null);
  const role = useUserStore((state) => state.role);

  useEffect(() => {
    (async () => {
      try {
        const { data: roleData } = await fetchRoles(true, 100, 1);
        let items: any[] = [
          ...roleData.data.map((role: Role) => ({
            name: role?.name,
            value: role?.id.toString(),
          })),
        ];
        if (items.length > 1) items.unshift({ name: "All roles", value: "" });
        setRoleFilter(items);
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (role) {
        const { data: usersData } = await fetchUsers(
          {
            page: 1,
            limit: LIMIT,
            extended: true,
            associated: true,
          },
          role
        );
        setUsers(usersData.data);
        setTotal(usersData.count);
        setLoading(false);
        if (loadRef?.current) {
          loadRef.current.scrollIntoView();
        }
      }
    })();
  }, [role]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          (user.email?.toUpperCase().includes(searchTerm.toUpperCase()) &&
            (!selectedRole || user.roleId === +selectedRole)) ||
          (!user.email &&
            !searchTerm &&
            (!selectedRole || user.roleId === +selectedRole))
      ),
    [searchTerm, selectedRole, users]
  );

  const totalPages = useMemo(
    () => calculateNumberOfPages(filteredUsers.length, PAGE_SIZE),
    [filteredUsers.length]
  );

  return (
    <Box width="100%" display="flex" sx={{ zIndex: 1 }} flexDirection="column">
      <Box
        alignItems="center"
        display="flex"
        width="100%"
        borderRadius="10px"
        bgcolor="white.main"
        flexDirection="column"
        sx={{
          px: {
            xs: "15px",
            md: "30px",
            lg: "35px",
          },
          py: {
            xs: "25px",
            md: "30px",
            lg: "35px",
          },
        }}
      >
        <Box
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          flexDirection="row"
          height="40px"
          width="100%"
          borderRadius="10px"
          bgcolor="white.main"
          sx={{
            mb: {
              xs: "15px",
              md: "30px",
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="18px"
          >
            <Typography variant="h4" color="black.main">
              Users ({total})
            </Typography>
          </Box>
          {/* <Button
            variant="contained"
            color="green1"
            sx={{
              height: "36px",
              width: "150px",
            }}
            onClick={() => navigate(`/users/new`)}
          >
            Create new user
          </Button> */}
        </Box>
        <Box
          alignItems="left"
          sx={{
            width: "100%",
            mb: { xs: "30px", lg: "20px" },
            justifyContent: { xs: "center", lg: "space-between" },
            display: "flex",
            maxHeight: {
              xs: "150px",
              lg: "70px",
            },
            flexDirection: { xs: "column", lg: "row" },
          }}
          data-testid="users-page-filtering-wrapper"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", ml: "flex-start" },
              gap: "16px",
              maxWidth: "760px",
              flexDirection: { xs: "column", ml: "row" },
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              maxHeight="40px"
              sx={{ ml: { md: "10px", lg: "0px" } }}
              data-testid="collections-page-filtering-search-wrapper"
            >
              <StyledTextField
                data-testid="collection-page-filtering-input"
                sx={{
                  width: "200px",
                }}
                id="search-collection-page-input"
                placeholder="Search"
                value={searchTerm}
                variant="outlined"
                onChange={(e: any) => {
                  setSearchTerm(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="search"
                      onClick={() => searchTerm && setSearchTerm("")}
                      sx={{ p: 0 }}
                    >
                      {searchTerm ? (
                        <Close fill="#C3D6E5" width="11px" height="11px" />
                      ) : (
                        <SearchIcon
                          color="#C3D6E5"
                          width="13px"
                          height="13px"
                          data-testid="right-action-search-input-search-icon"
                        />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Box>
            {/* commented for now until we have design for both super and brand admin role views */}
            {/* <Box
              height="40px"
              borderRadius="30px"
              sx={{
                display: "flex",
                width: "200px",
              }}
              data-testid="users-page-filtering-selector-wrapper"
            >
              <FilledSelect
                options={roleFilter}
                placeholder="Role"
                defaultValue={selectedRole}
                handleSelect={(e: any) => {
                  setSelectedRole(e.target.value);
                }}
              />
            </Box> */}
          </Box>
        </Box>
        {!loading ? (
          <Box
            bgcolor="white.main"
            borderRadius="6px"
            display="flex"
            p="6px"
            sx={{
              width: "100%",
              height: {
                xs: "380px",
                md: "300px",
                xl: "500px",
              },
              maxHeight: {
                xs: "480px",
                md: "300px",
                xl: "500px",
              },
            }}
          >
            <StyledTableContainer withoutBorder={true}>
              <Table>
                <TableHead>
                  <TableRow>
                    <UserStyledTableCell key={"Line"} align="left">
                      Email address
                    </UserStyledTableCell>
                    <UserStyledTableCell key={"Error"} align="left">
                      Role
                    </UserStyledTableCell>
                    <UserStyledTableCell key={"ColumnName"} align="left">
                      Azure Id
                    </UserStyledTableCell>
                    <UserStyledTableCell></UserStyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginate(filteredUsers, PAGE_SIZE, page).map((user) => {
                    return (
                      <TableRow key={user.id}>
                        <UserStyledTableCell align="left">
                          <Typography variant="body14" fontWeight="600">
                            {user.email}{" "}
                          </Typography>
                        </UserStyledTableCell>
                        {user.roleId && (
                          <UserStyledTableCell align="left">
                            <Typography variant="body14" fontWeight="600">
                              <Tag
                                maxWidth="100px"
                                text={
                                  user?.role?.name ||
                                  roleFilter.find(
                                    (role) =>
                                      role.value === user.roleId?.toString()
                                  )?.name ||
                                  ""
                                }
                                color="green1.main"
                              />
                            </Typography>
                          </UserStyledTableCell>
                        )}
                        <UserStyledTableCell align="left">
                          <Typography variant="body14" fontWeight="600">
                            {user.azureAdId}{" "}
                          </Typography>
                        </UserStyledTableCell>

                        <UserStyledTableCell sx={{ cursor: "pointer" }}>
                          <RedirectIcon />
                        </UserStyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Box>
        ) : (
          <Loader size={17} text="Loading" />
        )}
        {totalPages > 1 && (
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              count={totalPages}
              onChange={handlePageChange}
              color="primary"
              sx={{
                color: "red",
                "& ul li": {
                  color: "black.main",
                  "& button": {
                    color: "black.main",
                  },
                  "& div": {
                    color: "black.main",
                  },
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UsersPage;
