import { AppBar, Box } from "@mui/material";
import { MenuDrawer } from "components/MenuDrawer/MenuDrawer";
import { useState } from "react";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { useThemeStore } from "store/themeStore";

const Header = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const headerHeight = useThemeStore((state) => state.headerHeight);
  const showWhiteHeader = useThemeStore((state) => state.showWhiteHeader);

  return (
    <AppBar
      elevation={0}
      color="secondary"
      position="relative"
      sx={{
        height: headerHeight || { xs: "90px", lg: "110px" },
        mt: showWhiteHeader ? "-30px" : "0px",
      }}
      data-testid="header-wrapper"
      className="bg-gradient"
    >
      <Box
        sx={{
          display: { xs: showWhiteHeader ? "none" : "flex", lg: "none" },
          ml: { xs: "15px", md: "15px" },
          mt: { xs: "15px", md: "15px" },
        }}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          setOpenMenu(true);
        }}
      >
        <MenuIcon data-testid="hamburger-menu" />
      </Box>{" "}
      <MenuDrawer
        open={openMenu}
        handleClose={() => {
          setOpenMenu(false);
        }}
      />
    </AppBar>
  );
};

export default Header;
