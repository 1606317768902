import { createTheme } from "@mui/material/styles";
import {
  black,
  blue,
  green1,
  lightBorealBlue,
  lightGold,
  white,
  greyBlue,
  background,
  darkGold,
  error,
  success,
  warning,
  greyCarbon,
  border,
  openSeaBlue
} from "./palette";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    specialTag?: React.CSSProperties;
    nav?: React.CSSProperties;
    dataText?: React.CSSProperties;
    body16?: React.CSSProperties;
    body14?: React.CSSProperties;
    body12?: React.CSSProperties;
    body11?: React.CSSProperties;
    specialNumber?: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    specialTag?: React.CSSProperties;
    nav?: React.CSSProperties;
    dataText?: React.CSSProperties;
    body16?: React.CSSProperties;
    body14?: React.CSSProperties;
    body12?: React.CSSProperties;
    body11?: React.CSSProperties;
    specialNumber?: React.CSSProperties;
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    ml: true;
    md: true;
    lg: true;
    xl: true;
  }
  interface Palette {
    lightBorealBlue: Palette["primary"];
    white: Palette["primary"];
    blue: Palette["primary"];
    border: Palette["primary"];
    warning: Palette["primary"];
    green1: Palette["primary"];
    black: Palette["primary"];
    greyBlue: Palette["primary"];
    lightGold: Palette["primary"];
    darkGold: Palette["primary"];
    greyCarbon: Palette["primary"];
    openSeaBlue: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    green1: PaletteOptions["primary"];
    lightBorealBlue: PaletteOptions["primary"];
    border: PaletteOptions["primary"];
    black: PaletteOptions["primary"];
    greyBlue: PaletteOptions["primary"];
    lightGold: PaletteOptions["primary"];
    darkGold: PaletteOptions["primary"];
    greyCarbon: PaletteOptions["primary"];
    openSeaBlue: PaletteOptions["primary"];
  }

  interface PaletteColor {
    100?: string;
    80?: string;
    60?: string;
    40?: string;
    20?: string;
    10?: string;
    grey?: string;
    greyLight?: string;
    greyDark?: string;
    black?: string;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    specialTag: true;
    nav: true;
    dataText: true;
    termsAndConditions: true;
    connectMetaMask: true;
    logInMenuText: true;
    nftTitle: true;
    timeText: true;
    drawerMenuItem: true;
    walletWindowHeadline: true;
    body16: true;
    body14: true;
    body12: true;
    body11: true;
    specialNumber: true;
    termsAndConditionsFooter: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    lightBorealBlue: true;
    greyBlue: true;
    green1: true;
    openSeaBlue: true;
  }
}

// create breakpoints
const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 321,
      ml: 426,
      md: 769,
      lg: 1025,
      xl: 1440
    }
  }
});

const theme = createTheme(breakpointsTheme, {
  palette: {
    info: {
      main: "#FFFFFF"
    },
    primary: {
      ...green1,
      contrastText: white
    },
    green1: {
      ...green1,
      contrastText: white
    },
    secondary: {
      contrastText: white,
      ...black
    },
    black: {
      contrastText: white,
      ...black
    },
    lightBorealBlue: { ...lightBorealBlue, contrastText: white },
    error,
    success,
    warning,
    blue: {
      contrastText: white,
      ...blue
    },
    openSeaBlue: {
      contrastText: white,
      ...openSeaBlue
    },
    greyBlue,
    background,
    lightGold,
    darkGold,
    greyCarbon,
    border,
    white: {
      main: white,
      contrastText: black
    }
  },
  typography: {
    h1: {
      fontFamily: "Playfair Display",
      fontStyle: "normal",
      fontSize: "3.25rem",
      lineHeight: "3.875rem",
      fontWeight: 400,
      [breakpointsTheme.breakpoints.down("xl")]: {
        fontSize: "2.75rem",
        lineHeight: "3.375rem"
      },
      [breakpointsTheme.breakpoints.down("lg")]: {
        fontSize: "2.375rem",
        lineHeight: "2.625rem"
      }
    },
    h2: {
      fontFamily: "Playfair Display",
      fontStyle: "normal",
      fontSize: "2.625rem",
      lineHeight: "3.25rem",
      fontWeight: 400,
      [breakpointsTheme.breakpoints.down("xl")]: {
        fontSize: "2.125rem",
        lineHeight: "2.75rem"
      },
      [breakpointsTheme.breakpoints.down("lg")]: {
        fontSize: "1.75rem",
        lineHeight: "2.375rem"
      }
    },
    h3: {
      fontFamily: "Playfair Display",
      fontStyle: "normal",
      fontSize: "2rem",
      lineHeight: "2.625rem",
      fontWeight: 400,
      [breakpointsTheme.breakpoints.down("xl")]: {
        fontSize: "1.875rem",
        lineHeight: "2.5rem"
      },
      [breakpointsTheme.breakpoints.down("lg")]: {
        fontSize: "1.625rem",
        lineHeight: "2.25rem"
      }
    },
    h4: {
      fontFamily: "Playfair Display",
      fontStyle: "normal",
      fontSize: "1.375rem",
      lineHeight: "2rem",
      fontWeight: 400,
      [breakpointsTheme.breakpoints.down("xl")]: {
        fontSize: "1.25rem",
        lineHeight: "1.875rem"
      },
      [breakpointsTheme.breakpoints.down("lg")]: {
        fontSize: "1.125rem",
        lineHeight: "1.75rem"
      }
    },
    h5: {
      fontFamily: "Playfair Display",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      [breakpointsTheme.breakpoints.down("lg")]: {
        fontSize: "1rem",
        lineHeight: "1.625rem"
      }
    },
    body16: {
      fontFamily: "Source Sans Pro",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      [breakpointsTheme.breakpoints.down("lg")]: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem"
      }
    },
    body14: {
      fontFamily: "Source Sans Pro",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "0.875rem",
      lineHeight: "1.25rem"
    },
    body12: {
      fontFamily: "Source Sans Pro",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "0.75rem",
      lineHeight: "1.125rem"
    },
    body11: {
      fontFamily: "Source Sans Pro",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "0.688rem",
      lineHeight: "1rem"
    },
    nav: {
      fontFamily: "Source Sans Pro",
      fontWeight: 600,
      fontSize: "0.75rem",
      textTransform: "uppercase",
      lineHeight: "0.938rem",
      letterSpacing: "0.1em"
    },
    specialTag: {
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "0.625rem",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
      lineHeight: "0.813rem"
    },
    specialNumber: {
      fontFamily: "Times",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "1.375rem",
      lineHeight: "1.563rem"
    },
    button: {
      textTransform: "none",
      fontFamily: "Source Sans Pro",
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: "1.125rem"
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: "2rem",
          padding: "0.625rem 0.938rem",
          "&.Mui-disabled": {
            backgroundColor: green1[20],
            color: white
          },
          "&.Mui-disabled.MuiButton-outlined": {
            backgroundColor: "transparent",
            color: green1[20]
          }
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: black[80]
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none"
        }
      }
    }
  }
});

export default theme;
