import { AppBar, Box } from "@mui/material";
import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "components/Dialogs/ConfirmDialog";
import AuraBcLogo from "assets/logos/grey-aura-logo.svg";

const CustomCollectionHeader = () => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <AppBar
      elevation={0}
      color="secondary"
      position="relative"
      sx={{
        height: { xs: "60px", lg: "70px" },
        bgcolor: "white.main",
        m: "0px",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", lg: "flex" },
          ml: { xs: "15px", md: "15px" },
          mt: "16px",
        }}
        alignItems="center"
        justifyContent="left"
      >
        <ArrowBack
          color="secondary"
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenConfirmDialog(true)}
        />
        <Box
          sx={{
            height: "auto",
            maxHeight: { xs: "37px", lg: "60px" },
            width: "auto",
            alignSelf: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "156px",
            ml: { lg: "20px", xl: "35px" },
          }}
          component="img"
          alt="Aura World"
          src={AuraBcLogo}
        />
      </Box>
      <ConfirmDialog
        title={"Leave page"}
        text={`Are you sure you want to leave? All of your changes will be discarded.`}
        confirmBtnText={"Yes"}
        cancelBtnText="Cancel"
        open={openConfirmDialog}
        onConfirm={() => navigate(`/collections`)}
        onCancel={() => setOpenConfirmDialog(false)}
      />
    </AppBar>
  );
};

export default CustomCollectionHeader;
