export const IMPORT_CSV_TOKEN_HELPER: {
  id: number;
  name: string;
  example: string;
  description: string;
  required: boolean;
}[] = [
  {
    id: 1,
    name: "NFT_Title",
    example: "Demo Title",
    description: "Token title.",
    required: true,
  },
  {
    id: 2,
    name: "NFT_Description",
    example:
      "Demo description",
    description: "Description of inserted token.",
    required: true,
  },
  {
    id: 3,
    name: "Image_Path",
    example: "https://.../Image.jpg",
    description: "URL of token image.",
    required: true,
  },
  {
    id: 4,
    name: "MAX_Supply",
    example: "100",
    description: "Max supply each token will have.",
    required: true,
  },
];

export const REQUIRED_CSV_FIELDS = [
  "NFT_Title",
  "NFT_Description",
  "Image_Path",
];
export const TOKEN_LIMIT_PER_FILE = 10000;
export const MAX_SUPPLY_FIELD_NAME = "MAX_Supply";
