import React, { Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "layouts/Loading";
import Settings from "pages/Authenticated//Settings";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { ProtectedRoutes } from "utils/ProtectedRoutes";
import { useUserStore } from "store/userStore";
import mtmApiInstance from "utils/mtmApi";
import { protectedResourceScope } from "config/authConfig";
import Error from "pages/Unauthenticated/Login/Error";
import { Box, CircularProgress, Typography } from "@mui/material";
import Analytics from "pages/Authenticated/Analytics";
import { getUserInfo } from "services/userService";

const Home = React.lazy(() => import("./pages/Authenticated/HomePage"));
const Login = React.lazy(() => import("pages/Unauthenticated/Login/Login"));

const SmartContracts = React.lazy(
  () => import("pages/Authenticated/SmartContracts")
);
const SmartContractLibrary = React.lazy(
  () => import("pages/Authenticated/SmartContractLibrary/SmartContractLibrary")
);
const SmartContract = React.lazy(
  () => import("pages/Authenticated/SmartContracts/SmartContract/index")
);
const TokenDetail = React.lazy(
  () => import("pages/Authenticated/Token/TokenDetail")
);
const SmartContractForm = React.lazy(
  () => import("pages/Authenticated/SmartContracts/Form/index")
);
const ImportCsvFile = React.lazy(
  () =>
    import("pages/Authenticated/SmartContracts/Import tokens with CSV/index")
);

const NotFound = React.lazy(() => import("pages/NotFound"));

const RoutesList = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loggingIn, setLoggingIn] = useState<boolean>(true);
  const [loginError, setLoginError] = useState<unknown>();
  const navigate = useNavigate();

  useEffect(() => {
    const accessTokenRequest = {
      scopes: protectedResourceScope,
      account: account || undefined,
    };
    (async () => {
      if (!loggedIn && inProgress === InteractionStatus.None) {
        try {
          const tokenResponse = await instance.acquireTokenSilent(
            accessTokenRequest
          );
          const newToken = "Bearer " + tokenResponse.accessToken;
          const existingToken = localStorage.getItem("AccessToken");
          if (!existingToken) {
            navigate("/");
          }
          setLoggedIn(true);
          setLoggingIn(false);
          localStorage.setItem("AccessToken", tokenResponse.accessToken);
          mtmApiInstance.defaults.headers.common = {
            Authorization: newToken,
          };
          const {data: user} = await getUserInfo(true);
          useUserStore.setState({
            token: newToken,
            role: user?.role?.name,
            brandId: user?.brandId,
          });
        } catch (err) {
          console.log("Error logging in: ", err);
          if (err instanceof InteractionRequiredAuthError) {
            console.log("InteractionRequiredAuthError: ", err);
            await instance.acquireTokenRedirect(accessTokenRequest);
          } else {
            setLoggingIn(false);
            setLoginError(err);
          }
        }
      }
    })();
  }, [instance, account, inProgress, loggedIn]);

  if (loginError) {
    return (
      <Routes>
        <Route element={<Error error={loginError} />} path="*" />
      </Routes>
    );
  }

  return loggingIn ? (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="25px"
    >
      <Typography variant="h3" color="white.main">
        Logging in...
      </Typography>
      <CircularProgress />
    </Box>
  ) : (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <Home />
            </ProtectedRoutes>
          }
        />
        <Route path="/login" element={<Login />} />

        {/* Smartcontracts */}
        <Route
          path="/collections"
          element={
            <ProtectedRoutes>
              <SmartContracts />
            </ProtectedRoutes>
          }
        />

        {/* SmartContractLibrary */}
        {/* <Route
          path="/smart-contract-library"
          element={
            <ProtectedRoutes>
              <SmartContractLibrary />
            </ProtectedRoutes>
          }
        /> */}

        <Route
          path="/collections/:smartcontractId"
          element={
            <ProtectedRoutes>
              <SmartContract />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/collections/:smartcontractId/tokens/:tokenId"
          element={
            <ProtectedRoutes>
              <TokenDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/collections/new"
          element={
            <ProtectedRoutes>
              <SmartContractForm />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/collections/:scId/import-csv"
          element={
            <ProtectedRoutes>
              <ImportCsvFile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoutes>
              <Settings />
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="/analytics"
          element={
            <ProtectedRoutes>
              <Analytics />
            </ProtectedRoutes>
          }
        /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
export default RoutesList;
