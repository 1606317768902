import { Box, Container } from "@mui/material";
import { Container as MaterialContainer } from "@mui/system";

interface IPageLayoutProps {
  children: JSX.Element;
}

const PageLayout = ({ children }: IPageLayoutProps) => {
  return (
    <Box width="100%" sx={{ zIndex: 1, pl: { lg: "150px" } }}>
      <MaterialContainer maxWidth="xl">{children}</MaterialContainer>
    </Box>
  );
};

export default PageLayout;
