import { styled, TextField, TextFieldProps, Theme } from "@mui/material";

export const InputMessageTextField = styled(TextField)<TextFieldProps>(
  ({ theme }: { theme: Theme }) => ({
    width: "100%",
    "& .MuiInputBase-input": {
      fontFamily: "Source Sans Pro",
      fontSize: "12px",
      lineHeight: "1.125rem",
      color: theme.palette.black.main || "#C3D6E5",
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "& fieldset": {
        minHeight: "100px",
        borderColor: theme.palette?.border?.dark || "black",
      },
      "&:hover fieldset": {
        borderColor: theme.palette?.greyBlue?.dark || "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette?.greyBlue?.dark || "black",
      },
      "&.Mui-focused input": {
        color: theme.palette?.black.main || "black",
      },
    },
  })
);

export const StyledTextField = styled(TextField)<any>(
  ({ theme, error = false }: { theme: any; error?: boolean }) => ({
    "& .MuiInputBase-input": {
      padding: "0px 10px",
      fontSize: "12px",
      height: "40px",
      color: theme.palette.black.main || "#C3D6E5",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: error
          ? theme.pallete?.error.main
          : theme.palette?.background?.black,
        color: "black",
      },
      "&:hover fieldset": {
        borderColor: error
          ? theme.pallete?.error.main
          : theme.palette?.background?.black,
        borderRadius: "70px",
        color: "black",
      },

      borderRadius: "70px",
      "&.Mui-focused fieldset": {},
      "&.Mui-focused input": {
        color: theme.palette?.black.main || "black",
      },
    },
  })
);
