import { Configuration, RedirectRequest } from "@azure/msal-browser";

const {
  REACT_APP_ENVIRONMENT = "DEV",
  REACT_APP_TENANT_ID = "3a48c5a5-bfcb-4422-a662-d3a3e08bd90c", //DEV
  REACT_APP_CLIENT_ID = "069d7c32-2d5b-4f41-89f8-798f9ce5fc62", //DEV
  REACT_APP_API_SCOPE = "https://cloudaura.onmicrosoft.com/APP-LUX-SBT-API", //DEV
  REACT_APP_API_CLIENT_ID = "2f23171d-2de3-4b36-bd63-e155644d3764", //DEV
} = global;

const APP_ENV =
  REACT_APP_ENVIRONMENT === "BTA"
    ? "beta"
    : REACT_APP_ENVIRONMENT.toLowerCase();

const redirectUri =
  process.env.NODE_ENV !== "production"
    ? window.location.origin
    : `https://mtm.${APP_ENV}.auraluxurynft.com/`;

export const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`,
    redirectUri: redirectUri,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [`${REACT_APP_API_SCOPE}/access_as_user`],
};

export const protectedResourceScope = [`${REACT_APP_API_SCOPE}/access_as_user`];
