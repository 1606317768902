import mtmApiInstance from "utils/mtmApi";
import { AvailableRoles, Role, User, fetchUsersParams } from "utils/types";

export const getUserById = async (id: string) => {
  const { status, data } = await mtmApiInstance.get(`/api/accounts/${id}`);
  return {
    data,
    status,
  };
};

export const getUserInfo = async (associated = false) => {
  const params: fetchUsersParams = {};
  if (associated) {
    params.associated = associated;
  }
  const { status, data } = await mtmApiInstance.get(`/api/accounts/azure/oid`, {
    params,
  });
  return {
    data,
    status,
  };
};

export const fetchUsers = async (
  options: fetchUsersParams = {},
  role?: string | undefined
) => {
  const { page, limit, roleId, extended, searchTerm, associated } = options;
  let url = `/api/accounts`;
  const params: fetchUsersParams = {};
  if (extended) {
    params.extended = extended;
  }
  if (associated) {
    params.associated = associated;
  }
  if (page) {
    params.page = page;
  }
  if (limit) {
    params.limit = limit;
  }
  if (roleId) {
    params.roleId = roleId;
  }
  if (searchTerm) {
    params.searchTerm = searchTerm;
  }
  if (role === AvailableRoles.brandAdmin) {
    params.names = "Brand User,Brand Admin";
    url = `/api/accounts/roles/filter`;
  }
  const {
    data,
  }: {
    status: number;
    data: {
      data: User[];
      count: number;
      numberOfPages: number;
    };
  } = await mtmApiInstance.get(url, {
    params,
  });
  return {
    data: data,
  };
};

export const fetchRoles = async (
  extended: boolean,
  limit: number,
  page: number
) => {
  const params: fetchUsersParams = {};
  if (extended) {
    params.extended = extended;
  }
  if (page) {
    params.page = page;
  }
  if (limit) {
    params.limit = limit;
  }
  const {
    data,
  }: {
    status: number;
    data: {
      data: Role[];
      count: number;
      numberOfPages: number;
    };
  } = await mtmApiInstance.get(`/api/roles`, {
    params,
  });
  return {
    data: data,
  };
};
