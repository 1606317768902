import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../UI/Loader";
import { StyledDialog } from "../UI/StyledDialog";

export const ConfirmDialog = ({
  title,
  text,
  confirmBtnText,
  cancelBtnText,
  open,
  onConfirm,
  onCancel,
  showLoader = false,
  hideCancelBtn = false,
}: {
  title: string;
  text: string | JSX.Element;
  confirmBtnText: string;
  cancelBtnText?: string;
  open: boolean;
  hideCancelBtn?: boolean;
  showLoader?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledDialog
      contentgap="0px"
      open={open}
      fullWidth={lg}
      PaperProps={{
        sx: {
          m: 0,
          p: "20px",
          maxWidth: "560px",
          minHeight: showLoader ? "220px" : "259px",
          borderRadius: "10px",
        },
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onCancel}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box pt="10px">
          <Typography variant="h4" textAlign="center" color="black.100">
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: "20px",
            maxWidth: "450px",
            borderRadius: "8px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          {showLoader ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb="10px"
            >
              <Loader bgcolor="white.main" />
            </Box>
          ) : (
            <Typography variant="body14" color="black.100" textAlign="center">
              {text}
            </Typography>
          )}
        </Box>
        <Box
          m="20px"
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!hideCancelBtn && (
            <Button
              color="primary"
              variant="outlined"
              onClick={onCancel}
              sx={{ mr: { xs: "3px", md: "10px" } }}
            >
              {cancelBtnText || "Cancel"}
            </Button>
          )}
          {!showLoader && (
            <Button
              color="primary"
              variant="contained"
              onClick={onConfirm}
              sx={{ maxWidth: "160px" }}
            >
              {confirmBtnText}
            </Button>
          )}
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};
