export interface ERC20Token {
  address: string;
  decimals: number;
  symbol: string;
}

export interface Contract {
  address: string;
  name: string;
  network_id: number;
}

export interface Blockchain {
  id: number;
  name: string;
  dataConnexionInfo: string;
  type: number;
}
export interface SmartContract {
  id: number;
  name: string;
  bcAddress: string;
  blockchainId: number;
  ownerWalletId: number;
  brandId: number;
  description?: string;
  letterSymbol?: string;
  bannerUrl?: string;
  smartContractTemplateId?: number;
  smartContractTemplate?: SmartContractTemplate;
  createdAt?: string;
  brand?: Brand;
}

export interface SmartContractTemplate {
  id: number;
  name: string;
  type: ERCType;
  isStandard: boolean;
  license: LicenseType;
  brandId: number;
  templateUrl: string;
  description?: string;
}

export interface Brand {
  id: number;
  name: string;
  contactAccountId?: number;
}

export interface Token {
  id: number;
  tokenId: number;
  uniqueHash: string;
  ipfsStatus: IpfsStatus;
  smartContractId: number;
  brandId: number;
  importId?: number;
  smartContractType?: ERCType;
  title?: string;
  description?: string;
  attributes?: string;
  imgCid?: string;
  metadataCid?: string;
  imagePath?: string;
  imgBucketPath?: string;
  metadataBucketPath?: string;
  ipfsErrorMessage?: string;
  sellValue?: number;
  maxSupply?: number;
  smartContract?: SmartContract;
  mintTokenData?: MintTokenData[];
}

export interface MintTokenData {
  id: number;
  uniqueHash: string;
  ownerWalletAddress: string;
  status: TokenStatus;
  errorMessage?: string;
  mintDate?: string;
  createdAt?: string;
}

export interface Transaction {
  id: number;
  txId: string;
  txHash?: string;
  functionId: number;
  status: TransactionStatus;
  smartContractId: number;
  response?: string;
  errorMessage?: string;
}

export declare enum TransactionStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export interface Role {
  id: number;
  name: string;
}

export interface User {
  id: number;
  email: string;
  azureAdId?: string;
  createdAt?: string;
  brandId?: number;
  roleId?: number;
  role?: Role;
}

export enum TokenStatus {
  PENDING = "PENDING",
  PENDING_DELIVERY = "PENDING_DELIVERY",
  DELIVERED = "DELIVERED",
  BURNED = "BURNED",
  MINTED = "MINTED",
  ERROR = "ERROR",
}

export enum LicenseType {
  BRONZE = "BRONZE",
  SILVER = "SILVER",
  GOLD = "GOLD",
  CUSTOM = "CUSTOM",
}

export enum ERCType {
  ERC1155 = "1155",
  ERC721 = "721",
}

export enum IpfsStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface Filter {
  key?: string;
  icon?: React.ReactNode;
  name: string;
  value: string;
}

export type FileRequirements = {
  type: "image";
  requiredHeight?: number;
  requiredWidth?: number;
};

export type UploadImageType =
  | "brandLogo"
  | "collectionBanner"
  | "collectionProfileImage"
  | "collectionTeasingImage"
  | "artistLogo";

export type ImageFile = File & { preview: string };

export interface fetchAllItemsParams {
  page?: number;
  limit?: number;
  extended?: boolean;
  associated?: boolean;
  blockchainId?: number;
  ERCType?: string;
  orderBy?: string;
  searchTerm?: string;
  orderDirection?: string;
}

export interface fetchUsersParams {
  page?: number;
  limit?: number;
  extended?: boolean;
  searchTerm?: string;
  associated?: boolean;
  roleId?: number;
  names?: any
}

export enum AvailableRoles {
  brandAdmin = "Brand Admin",
  superAdmin = "Super Admin",
  brandUser = "Brand User",
  app = "App",
}


export type CsvFileError = {
  line: number;
  fields: {
    columnName: string;
    error: { name: string; message: string };
  }[];
};

export type SmartContractTemplateResponse = {
  template: SmartContractTemplate | undefined;
  parameters: SmartContractTemplateParam[];
};

export type SmartContractTemplateParam = {
  internalType: string;
  name: string;
  type: string;
};
