import { hexValue } from "ethers/lib/utils";
import zipObject from "lodash/zipObject";

export interface EthereumChainParameter {
  chainId: string;
  chainName: string;
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls?: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[];
}

export const POLYGON_MAINNET = {
  method: "wallet_addEthereumChain",
  chainId: hexValue(137),
  chainName: "Polygon Mainnet",
  rpcUrls: ["https://polygon-rpc.com"],
  nativeCurrency: {
    name: "Matic",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://polygonscan.com/address/"],
};

export const POLYGON_MUMBAI = {
  chainId: hexValue(80001),
  chainName: "Mumbai Testnet",
  rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://mumbai.polygonscan.com/address/"],
};

export const LOCAL = {
  chainId: hexValue(12345),
  chainName: "LOCALHOST",
  rpcUrls: ["http://localhost:8545"],
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrls: [],
};

export const PALM_MAINNET = {
  method: "wallet_addEthereumChain",
  chainId: hexValue(11297108109),
  chainName: "Palm",
  rpcUrls: [
    "https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b",
  ],
  nativeCurrency: {
    name: "PALM",
    symbol: "PALM",
    decimals: 18,
  },
  blockExplorerUrls: ["https://explorer.palm.io/"],
};

export const ETH_MAINNET = {
  method: "wallet_switchEthereumChain",
  chainId: hexValue(1),
  chainName: "Ethereum",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrls: ["https://etherscan.io/address/","https://etherscan.io/tx/"],
};

export const RINKEBY = {
  method: "wallet_switchEthereumChain",
  chainId: hexValue(4),
  chainName: "Rinkeby Test Network",
  rpcUrls: ["https://rinkeby.etherscan.io"],
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrls: ["https://rinkeby.etherscan.io/address/"],
};

export const networks = [
  POLYGON_MAINNET,
  RINKEBY,
  PALM_MAINNET,
  ETH_MAINNET,
  LOCAL,
];

export const CHAIN_ID_TO_NETWORK = zipObject(
  networks.map((n) => n.chainId),
  networks
);

export const getNetworkName = (
  chainId: string | number
): string | undefined => {
  let chainDetails: EthereumChainParameter | undefined;
  if (typeof chainId === "string") {
    chainDetails = chainDetails =
      CHAIN_ID_TO_NETWORK[hexValue(chainId)] === undefined
        ? undefined
        : CHAIN_ID_TO_NETWORK[hexValue(chainId)];
  }
  chainDetails =
    CHAIN_ID_TO_NETWORK[hexValue(chainId)] === undefined
      ? undefined
      : CHAIN_ID_TO_NETWORK[hexValue(chainId)];
  return chainDetails?.chainName;
};

export const getNetworkDetails = (
  chainId: string | number
): EthereumChainParameter | undefined => {
  let chainDetails: EthereumChainParameter | undefined;
  if (typeof chainId === "string") {
    chainDetails = chainDetails =
      CHAIN_ID_TO_NETWORK[hexValue(chainId)] === undefined
        ? undefined
        : CHAIN_ID_TO_NETWORK[hexValue(chainId)];
  }
  chainDetails =
    CHAIN_ID_TO_NETWORK[hexValue(chainId)] === undefined
      ? undefined
      : CHAIN_ID_TO_NETWORK[hexValue(chainId)];

  return chainDetails;
};

export const getBlockExploreTxLink = (tx: any, networkId?: number | string) => {
  if (!networkId) return "";

  if (networkId === 1 || networkId === hexValue(1)) {
    return `https://etherscan.io//tx/${tx && tx.hash ? tx.hash : tx}`;
  }

  const networkDetails = getNetworkDetails(networkId);
  if (
    !networkDetails?.blockExplorerUrls ||
    networkDetails.blockExplorerUrls.length === 0
  ) {
    return "";
  }

  return `${networkDetails.blockExplorerUrls[0]}/tx/${
    tx && tx.hash ? tx.hash : tx
  }`;
};
