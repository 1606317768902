import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const Tag = ({
  text,
  color,
  textcolor = 'white.main',
  maxWidth = 'none',
}: {
  text: string;
  color: string;
  textcolor?: string;
  maxWidth?: string;
}) => (
  <Box
    sx={{
      m: '2px',
      p: '4px 10px',
    }}
    bgcolor={color}
    borderRadius="40px"
    zIndex={2}
    data-testid="upcoming-tag"
    display="flex"
    alignItems="center"
    justifyContent="center"
    maxWidth={maxWidth}
  >
    <Typography variant="specialTag" color={textcolor}>
      {text}
    </Typography>
  </Box>
);

export default Tag;
