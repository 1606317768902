import axios from "axios";
import { BigNumberish } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import {
  ETH_MAINNET,
  PALM_MAINNET,
  POLYGON_MAINNET,
  POLYGON_MUMBAI,
  RINKEBY,
} from "./networks";
import { CsvFileError, ERC20Token, ERCType } from "./types";
import { isAddress } from "@ethersproject/address";
import { Web3Provider } from "@ethersproject/providers";
import { Theme } from "@mui/material";
import {
  MAX_SUPPLY_FIELD_NAME,
  REQUIRED_CSV_FIELDS,
  TOKEN_LIMIT_PER_FILE,
} from "./helpOnCsvTokenFields";

const TOKEN_TYPE_URL_MAP: { [x: string]: string } = {
  ERC1155: "tokentxnsErc1155",
  ERC721: "tokentxnsErc721",
};

const OPEN_SEA_BASE_URL = "https://opensea.io/assets/";

export const formatEthereumAddress = (address: string) =>
  `${address.substr(0, 10)}...${address.substr(address.length - 4, 4)}`;

export const updateUniqueSCArray = (data: any[], newData: any[]) => {
  const shallowCopy = [...data];
  const ids = shallowCopy.map((i) => i?.id);
  if (newData.length) {
    newData.forEach((nd) => {
      if (!ids.includes(nd?.id)) {
        shallowCopy.push(nd);
      }
    });
  }
  return shallowCopy;
};

export const formatCryptoCurrency = (
  value: BigNumberish,
  currency: ERC20Token,
  { symbol = false } = {}
) => {
  const formattedSymbol = symbol && currency.symbol ? currency.symbol : "";
  let formattedUnit = formatUnits(value, currency.decimals);
  // BLOCK OF CODE FOR TESTING PURPOSE, SOME OF THE PRICES ARE COMING AS 18 DIGITS NUMBERS
  if (formattedUnit.length > 8) {
    formattedUnit =
      formattedUnit.length >= 15
        ? formattedUnit.substring(0, formattedUnit.length - 10)
        : formattedUnit.substring(0, formattedUnit.length - 6);
    return `${formattedUnit} ${formattedSymbol}`.trimEnd();
  }

  return `${formattedUnit} ${formattedSymbol}`.trimEnd();
};

export const truncateString = (str: string, num: number) => {
  if (str.length + 3 <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const capitalize = (string: string) =>
  string[0].toUpperCase() + string.slice(1).toLowerCase();

export const isValidHttpUrl = (string?: string) => {
  if (!string) {
    return false;
  }
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const openOnEtherscan = (address?: string, networkID?: number): void => {
  if (networkID && networkID === 4) {
    window.open(`${RINKEBY.blockExplorerUrls}${address}`);
  } else {
    window.open(`${ETH_MAINNET.blockExplorerUrls[0]}${address}`);
  }
};

export const openOnOpenSea = (openSeaUrl?: string): void => {
  window.open(openSeaUrl);
};

export const openOnPolygonscan = (address?: string): void => {
  window.open(`${POLYGON_MAINNET.blockExplorerUrls}${address}`);
};

export const generateOpenSeaUrl = (
  networkId?: number,
  tokenAddres?: string,
  tokenId?: string
): string => {
  if (!networkId || !tokenAddres || !tokenId) {
    return "";
  }
  let network;
  if (networkId === 1) {
    network = "ethereum";
  }
  if (networkId === 137) {
    network = "matic";
  }
  if (!network) {
    return "";
  }
  return new URL(
    `${network}/${tokenAddres}/${tokenId}`,
    OPEN_SEA_BASE_URL
  ).toString();
};

export const generateBlockExploreUrl = (
  address: string | undefined,
  networkId: number,
  tokenType?: string | false
): string => {
  if (!address) {
    return "#";
  }
  let url;
  if (networkId === 1) {
    url = new URL(address, ETH_MAINNET.blockExplorerUrls[0]).toString();
  }
  if (networkId === 4) {
    url = new URL(address, RINKEBY.blockExplorerUrls[0]).toString();
  }
  if (networkId === 137) {
    url = new URL(address, POLYGON_MAINNET.blockExplorerUrls[0]).toString();
  }
  if (networkId === 80001) {
    url = new URL(address, POLYGON_MUMBAI.blockExplorerUrls[0]).toString();
  }
  if (networkId === 11297108109) {
    url = new URL(address, PALM_MAINNET.blockExplorerUrls[0]).toString();
  }
  if (!url) {
    return "#";
  }
  if (tokenType) {
    return `${url}#${TOKEN_TYPE_URL_MAP[tokenType]}`;
  }
  return url;
};
export const checkIfFileIsImage = (filename: string) =>
  /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(filename?.toLowerCase());

export const setNftImageSize = async (
  imageUrl: string,
  setNftSize: (value: string) => void
) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setNftSize(`${img.naturalHeight}px * ${img.naturalWidth}px`);
  };
  img.onerror = (err) => {
    console.log("img error");
    console.error(err);
  };
};

export const getExtensionFromUrl = async (url: string) => {
  if (checkIfFileIsImage(url)) {
    const splitted = url.split(/[#?]/);
    const first = splitted[0];
    if (first) {
      const ext = first.split(".").pop();
      return `Image (.${ext?.trim()})`;
    }
    return "Invalid";
  }
  const img = await axios.get(url);
  const type = img.headers["content-type"].split("/");
  return `${capitalize(type[0])} (.${type[1]})`;
};

export const openSocialMedia = (mediaLink: string): void => {
  window.open(`${mediaLink}`);
};

export const calculateNoOfCards = (
  xl: boolean,
  md: boolean,
  sm: boolean,
  bigCards: boolean
) => {
  if (xl) return bigCards ? 3 : 4;
  if (md) return bigCards ? 2 : 3;
  if (sm) return 2;
  return 1;
};

export const checkDeployment = async (
  provider: Web3Provider,
  contractAddress: string
): Promise<boolean> => {
  if (!isAddress(contractAddress)) return false;
  const bytecode = await provider.getCode(contractAddress);
  return bytecode !== "0x";
};

export const formatEuroPrice = (price: number) => {
  const priceAsString = price.toString();
  return priceAsString.slice(0, priceAsString.lastIndexOf(".") + 3);
};

export const checkIfStuckError = (error: any) =>
  error?.code === -32002 &&
  (error?.message.toLowerCase().includes("already pending") ||
    error?.message.toLowerCase().includes("already processing"));

export const calculateLoadMoreText = (
  total: number,
  page: number,
  limit: number
) => {
  const remaining = total - page * limit;
  return remaining > limit ? limit : remaining;
};

export const openOnEtherscanTx = (
  TransactionHash?: string,
  networkID?: number
): void => {
  if (networkID && networkID === 4) {
    window.open(`https://rinkeby.etherscan.io/tx/${TransactionHash}`);
  } else {
    window.open(`${ETH_MAINNET.blockExplorerUrls[1]}${TransactionHash}`);
  }
};

export const calculateNumberOfPages = (count: number, limit: number): number =>
  count < limit ? 1 : Math.ceil(count / limit);

export const getColor = (trigger: boolean, error: boolean, sub: number) => {
  if (trigger) {
    return `green.${sub}`;
  }
  if (error) {
    if (sub !== 100 && sub !== 10) {
      sub = 100;
    }
    return `error.${sub}`;
  }
  return `blue.${sub}`;
};

export const getColorFromTheme = (
  theme: Theme,
  trigger: boolean,
  error: boolean
) => {
  if (trigger) {
    return theme.palette.green1.main;
  }
  if (error) {
    return theme.palette.error.main;
  }
  return theme.palette.blue.main;
};

export const isUrlValid = (imgUrl: string) => {
  try {
    new URL(imgUrl);
    return true;
  } catch (error) {
    return false;
  }
};

export const CsvErrorMessages = {
  emptyField: {
    name: "Empty field",
    message: "Field is empty. Please fill in the value.",
  },
  wrongFormat: {
    name: "Wrong format",
    message: "Wrong format, invalid parameter type.",
  },
  maxSupply721: {
    name: "Max supply",
    message: "Max supply for ERC-721 is 1.",
  },
  minSupply: {
    name: "Minimum supply",
    message: "Minimum token supply is 1.",
  },
  invalidLink: {
    name: "Invalid link",
    message: "Image Path link is invalid. Please check and input again.",
  },
};

export const validateCSVFile = (
  parsedFile: any[],
  scType: string,
  existingFields: string[],
  requiredCsvFields: string[],
  setError: (message: string) => void
): CsvFileError[] => {
  const errors: CsvFileError[] = [];
  if (scType === ERCType.ERC1155) {
    requiredCsvFields.push(MAX_SUPPLY_FIELD_NAME);
  }
  const fieldsIncluded = requiredCsvFields.every((v) =>
    existingFields.includes(v)
  );
  setError(
    parsedFile.length > TOKEN_LIMIT_PER_FILE
      ? `Limit ${TOKEN_LIMIT_PER_FILE} reached. Please try with smaller number of tokens.`
      : ""
  );
  setError(
    fieldsIncluded
      ? ""
      : `Some field names are invalid. Please check again that file contains following fields: ${requiredCsvFields}`
  );
  parsedFile.forEach((row, index) => {
    const fields = Object.keys(row);
    let currentRow: CsvFileError = {
      line: index,
      fields: [],
    };
    fields.forEach((key) => {
      let error;
      let value = row[key];
      if (key === MAX_SUPPLY_FIELD_NAME) {
        if (Number.isNaN(+value)) {
          error = CsvErrorMessages.wrongFormat;
        }
        if (value === 0) {
          error = CsvErrorMessages.minSupply;
        }
        if (scType === ERCType.ERC721 && value > 1) {
          error = CsvErrorMessages.maxSupply721;
        }
      } else {
        if (
          REQUIRED_CSV_FIELDS.find((field) => field === key) &&
          value === ""
        ) {
          error = CsvErrorMessages.emptyField;
        }
        if (key === "Image_Path" && !isUrlValid(value)) {
          error = CsvErrorMessages.invalidLink;
        }
      }
      if (error)
        currentRow.fields.push({
          columnName: key,
          error,
        });
    });
    if (currentRow.fields.length) errors.push(currentRow);
  });
  return errors;
};
