import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LeftArrow } from "assets/icons/left-arrow.svg";

const BackLink: FC<{ to: string; text: string; light?: boolean }> = ({ to, text, light }) => {
  return (
    <Link to={to}>
      <Box display="flex" alignItems="center" gap="8px">
        <LeftArrow height="11px" width="11px" />
        <Typography
          variant="body11"
          color={light ? "greyBlue.light" : "greyBlue.dark"}
          fontSize="10px"
        >
          {text}
        </Typography>
      </Box>
    </Link>
  );
};

export default BackLink;
