import styled from "@emotion/styled";
import { TableCell, tableCellClasses, TableContainer } from "@mui/material";

export const StyledTableCell = styled(TableCell)<any>(
  ({
    theme,
    headerBgColor,
    padding = "5px",
    withoutBorder = false,
  }: {
    theme: any;
    padding: string;
    withoutBorder: boolean;
    headerBgColor: string;
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: headerBgColor ? headerBgColor : theme.palette.white.main,
      border: withoutBorder ? "none" : `1px solid ${theme.palette.border.dark}`,
      padding: padding,
      ...theme.typography.specialTag,
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "transparent",
      border: withoutBorder ? "none" : `1px solid ${theme.palette.border.dark}`,
      borderBottom: `1px solid ${theme.palette.border.dark}`,
      padding: padding,
      ...theme.typography.body14,
    },
    color: theme.palette.black.main,
  })
);

export const StyledTableContainer = styled(TableContainer)<any>(
  ({
    theme,
    headerBgColor,
    padding = "5px",
    withoutBorder = false,
  }: {
    theme: any;
    padding: string;
    withoutBorder: boolean;
    headerBgColor: string;
  }) => ({
    borderRadius: "4px",
    border: withoutBorder ? "none" : `1px solid ${theme.palette.border.dark}`,
  })
);
