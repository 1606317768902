import CustomCollectionHeader from "layouts/Header/CustomCreateCollectionHeader";
import Header from "layouts/Header/Header";
import SideNav from "layouts/LeftBar/LeftBar";
import PageLayout from "layouts/PageLayout";
import { useThemeStore } from "store/themeStore";

interface IProtectedRoutesProps {
  children: JSX.Element;
}

export const ProtectedRoutes = ({ children }: IProtectedRoutesProps) => {
  const showWhiteHeader = useThemeStore((state) => state.showWhiteHeader);
  return (
    <>
      {showWhiteHeader && <CustomCollectionHeader />}
      <Header />
      <SideNav />
      <PageLayout>{children}</PageLayout>
    </>
  );
};
