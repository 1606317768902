import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BackLink from "components/UI/BackLink";
import BreadcrumbsNavigation from "components/UI/BreadcrumbsNavigation";
import { useState } from "react";
import UsersPage from "./Users/UsersPage";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";

const Settings = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <Box
      width="100%"
      display="flex"
      data-testid="aboutpage-wrapper"
      mt="-80px"
      sx={{ zIndex: 1 }}
    >
      {" "}
      <Box
        sx={{
          position: "absolute",
          left: { xs: "23px", lg: "230px", xl: "310px" },
          top: { xs: "38px", md: "12px", lg: "40px" },
        }}
      >
        {downLg ? (
          <BackLink to="/" text="Home" />
        ) : (
          <BreadcrumbsNavigation breadcrumbs={[{ text: "Settings" }]} />
        )}
      </Box>
      <Grid container display="flex" justifyContent="center" width="100%">
        <Grid item xs={12} md={12} lg={11} xl={10} justifyContent="center">
          <Box
            alignItems="center"
            justifyContent="left"
            display="flex"
            height="102px"
            width="100%"
            borderRadius="10px"
            bgcolor="white.main"
            sx={{
              px: { xs: "20px", md: "25px", lg: "35px" },
              mt: { xs: "25px", lg: "0px" },
            }}
            mb="20px"
          >
            <Box alignItems="left" display="flex" gap="15px" mt="14px">
              <SettingsIcon
                height="32px"
                width="32px"
                stroke="black"
                style={{ marginTop: "5px" }}
              />
              <Typography variant="h3" color="black.main">
                Settings
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={11}
          xl={10}
          justifyContent="center"
          mb="30px"
        >
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            borderRadius="10px"
            bgcolor="white.main"
            flexDirection="column"
          >
            <Box
              alignItems="center"
              display="flex"
              width="100%"
              borderRadius="10px 10px 0px 0px"
              bgcolor="background.dark"
              flexDirection="row"
              height="60px"
              gap="15px"
              sx={{
                px: { xs: "15px", md: "20px", lg: "25px" },
                gap: { xs: "10px", md: "15px" },
              }}
            >
              <Box
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                display="flex"
                width="50px"
                flexDirection="row"
                height="60px"
                sx={{
                  cursor: "pointer",
                  borderBottom: selectedTab === 0 ? "3px solid" : "none",
                  borderColor: "#00FFD2",
                }}
                onClick={() => setSelectedTab(0)}
              >
                <Typography
                  variant="body16"
                  sx={{ fontWeight: selectedTab === 0 ? 600 : 400 }}
                  color="white.main"
                >
                  Users
                </Typography>
              </Box>
              <Box
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                display="flex"
                width="50px"
                flexDirection="row"
                height="60px"
                sx={{
                  cursor: "pointer",
                  borderBottom: selectedTab === 1 ? "3px solid" : "none",
                  borderColor: "#00FFD2",
                }}
                onClick={() => setSelectedTab(1)}
              >
                <Typography
                  variant="body16"
                  sx={{ fontWeight: selectedTab === 1 ? 600 : 400 }}
                  color="white.main"
                >
                  Roles
                </Typography>
              </Box>
              <Box
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                display="flex"
                width="80px"
                flexDirection="row"
                height="60px"
                sx={{
                  cursor: "pointer",
                  borderBottom: selectedTab === 2 ? "3px solid" : "none",
                  borderColor: "#00FFD2",
                }}
                onClick={() => setSelectedTab(2)}
              >
                <Typography
                  variant="body16"
                  sx={{ fontWeight: selectedTab === 2 ? 600 : 400 }}
                  color="white.main"
                >
                  Templates
                </Typography>
              </Box>
              <Box
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                display="flex"
                width="70px"
                flexDirection="row"
                height="60px"
                sx={{
                  cursor: "pointer",
                  borderBottom: selectedTab === 3 ? "3px solid" : "none",
                  borderColor: "#00FFD2",
                }}
                onClick={() => setSelectedTab(3)}
              >
                <Typography
                  variant="body16"
                  sx={{ fontWeight: selectedTab === 3 ? 600 : 400 }}
                  color="white.main"
                >
                  Wallet
                </Typography>
              </Box>
              <Box
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                display="flex"
                width="110px"
                flexDirection="row"
                height="60px"
                sx={{
                  cursor: "pointer",
                  borderBottom: selectedTab === 4 ? "3px solid" : "none",
                  borderColor: "#00FFD2",
                }}
                onClick={() => setSelectedTab(4)}
              >
                <Typography
                  variant="body16"
                  sx={{ fontWeight: selectedTab === 4 ? 600 : 400 }}
                  color="white.main"
                >
                  Email Settings
                </Typography>
              </Box>
            </Box>
          </Box>
          {selectedTab === 0 && <UsersPage />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
