import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import MainContent from "./pages/MainContent";

function App({ instance }: { instance: PublicClientApplication }) {
  return (
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <MainContent />
      </MsalProvider>
    </BrowserRouter>
  );
}

export default App;
