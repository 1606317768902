export const white = "#FFFFFF";

export const blue = {
  main: "#0A5FDE",
  100: "#0A5FDE",
  80: "#3B7FE5",
  60: "#6C9FEB",
  40: "#9DBFF2",
  20: "#CEDFF8",
  10: "#E7EFFC"
};

export const error = {
  main: "#CC1F1F",
  10: "#CC1F1F1A"
};
export const success = {
  main: "#139F5C"
};
export const warning = {
  main: "#DA942C"
};

export const greyCarbon = {
  main: "#2E3131"
};

export const background = {
  main: "#F5F7F9",
  grey: "#F5F7F9",
  light: "#292E32",
  greyLight: "#292E32",
  dark: "#1E2327",
  greyDark: "#1E2327",
  black: "#161A1D",
  upcomingCard: "#081B2C",
  brandLogo: "#0F273D"
};

export const greyBlue = {
  light: "#C3D6E5",
  main: "#C3D6E5",
  dark: "#8CA0B1"
};

export const black = {
  main: "#000000",
  100: "#000000",
  80: "#333333",
  60: "#666666",
  40: "#999999",
  20: "#CCCCCC",
  10: "#E5E5E5"
};

export const green1 = {
  main: "#006A60",
  dark: "#005757",
  light: "#338880",
  100: "#006A60",
  80: "#338880",
  60: "#66A6A0",
  40: "#99C3BF",
  20: "#CCE1DF",
  10: "#E5F0EF"
};

export const openSeaBlue = {
  main: "#2081E2",
  dark: "#1766b5",
  light: "#4f9ce8"
};

export const lightBorealBlue = {
  main: "#00FFD2",
  100: "#00FFD2",
  80: "#33FFDB",
  60: "#66FFE4",
  40: "#99FFED",
  20: "#CCFFF6",
  10: "#E5FFFB"
};

export const lightGold = {
  main: "#D6A30C",
  100: "#D6A30C",
  80: "#DEB53D",
  60: "#E6C86D",
  40: "#EFDA9E",
  20: "#F7EDCE",
  10: "#FBF6E7"
};

export const darkGold = {
  main: "#995814",
  100: "#995814",
  80: "#AD7943",
  60: "#C29B72",
  40: "#D6BCA1",
  20: "#EBDED0",
  10: "#F5EEE7"
};

export const border = {
  light: "#C3D6E5",
  dark: "#384550",
  data: "#1D3346",
  inner: "#344756"
};
