import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps
} from "@mui/material/CircularProgress";

const Loader = (
  props: CircularProgressProps & { text?: string; textcolor?: string; bgcolor?: string }
) => {
  return (
    <Box
      sx={{ position: "relative" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="10px"
      width="fit-content"
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: props.bgcolor || "background.light"
        }}
        size={props.size || 40}
        thickness={props.thickness || 4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "lightBorealBlue.main",
          animationDuration: "1s",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
          }
        }}
        size={props.size || 40}
        thickness={props.thickness || 4}
        {...props}
      />
      {props.text && (
        <Typography
          variant="body12"
          color={props.textcolor || "white.main"}
          sx={{ fontWeight: 500, fontStyle: "italic", fontSize: { xl: "0.875rem" } }}
        >
          {props.text}
        </Typography>
      )}
    </Box>
  );
};

export default Loader;
