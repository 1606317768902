import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Loading = ({ loadingText = "Loading..." }) => (
  <Box
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Typography sx={{ mb: 2 }} variant="h1">
      {loadingText}
    </Typography>
    <CircularProgress />
  </Box>
);

export default Loading;
